export const init = (containerId: string, dataLayerName = "dataLayer"): void => {
  (function (w: Window, d: Document, s: string, l: string, i: string) {
    //@ts-ignore
    w[l] = w[l] || [];
    //@ts-ignore
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s) as HTMLScriptElement,
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "//ssgtm.telia.se/gtm.js?id=" + i + dl;
    //@ts-ignore
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", dataLayerName, containerId);
};
